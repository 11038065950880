<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>直播带货</el-breadcrumb-item>
      <el-breadcrumb-item>选品广场</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <h3>选品广场</h3>
      <el-table
        ref="showPageData"
        :data="showPageData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="商品名称" width="180">
          <template slot-scope="scope">
            <div style="display: flex">
              <img
                width="45"
                height="45"
                :src="scope.row.previewUrl"
                alt="商品图"
              />
              &nbsp;&nbsp;
              <div>
                <div style="color: #435b86">{{ scope.row.goodsName }}</div>
                <div>ID:{{ scope.row.id }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="goodsPrice" label="当前价" width="180">
        </el-table-column>
        <el-table-column prop="totalSales" label="销量"> </el-table-column>
        <el-table-column prop="stockNum" label="库存"> </el-table-column>
      </el-table>
      <!-- <el-pagination background layout="prev, pager, next" :total="1000">
      </el-pagination> -->
      <div class="pagebottom">
        <el-pagination
          background
          @size-change="_handleSizeChange"
          @current-change="_handleCurrentChange"
          :current-page="queryInfo.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="tablePage.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="shopTotal"
        >
        </el-pagination>
        <el-button class="nextStep" type="primary" @click="addBabyDialog">
          下一步
        </el-button>
      </div>
    </div>
    <el-dialog
      title="宝贝"
      :visible.sync="babyDialogVisible"
      :append-to-body="true"
      width="50%"
      :before-close="handleClose"
      class="babyDialog"
    >
      <el-table
        ref="multipleSelection"
        :data="multipleSelection"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="dialogHandleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="全选">
          <template slot-scope="scope">
            <div style="display: flex">
              <img
                width="45"
                height="45"
                :src="scope.row.previewUrl"
                alt="商品图"
              />
              &nbsp;&nbsp;
              <div>
                <div style="color: #435b86">{{ scope.row.goodsName }}</div>
                <div>￥{{ scope.row.goodsPrice }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="" width="180">
          <template slot-scope="scope">
            <el-button size="mini" round @click="deleteBaby(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="babyDialogVisible = false"
          >上一步</el-button
        >
        <el-button type="primary" @click="pushToBabyPocket"
          >推送到宝贝口袋</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  asyncShoppingList,
  deleteBabyData,
  insertLiveGoods,
  getMyLive,
  searchBusinessInfoByUserId,
} from "@/api";
import { Message } from "element-ui";
export default {
  data() {
    return {
      tablePage: {
        size: 10,
      },
      babyDialogVisible: false,
      myLiveInfo: { user_id: window.sessionStorage.getItem("userId") },
      queryInfo: {
        roomId: 1,
        page: 1,
        businessId: 0,
      },
      tableData: [
        {
          name: "安阳扁粉菜",
          price: "0.9",
          salesVolume: "20",
          inventory: "9900",
        },
        {
          name: "安阳扁粉菜2",
          price: "0.9",
          salesVolume: "20",
          inventory: "9900",
        },
        {
          name: "安阳扁粉菜3",
          price: "0.9",
          salesVolume: "20",
          inventory: "9900",
        },
        {
          name: "安阳扁粉菜4",
          price: "0.9",
          salesVolume: "20",
          inventory: "9900",
        },
        {
          name: "安阳扁粉菜5",
          price: "0.9",
          salesVolume: "20",
          inventory: "9900",
        },
      ],
      showPageData: [],
      shopTotal: 0,
      businessId: 0,
      roomId: "0",
      multipleSelection: [],
      dialogMultipleSelection: [],
    };
  },
  methods: {
    addBabyDialog() {
      this.babyDialogVisible = true;
    },
    _handleSizeChange(newSize) {
      this.tablePage.size = newSize;
      if (this.tableData.length !== 0) {
        this.showPageData = this.tableData.slice(0, newSize);
        // if (newSize === 10) {
        //   this.showPageData = this.tableData.slice(0, 9);
        // }
        // if (newSize === 20) {
        //   this.showPageData = this.tableData.slice(0, 19);
        // }
        // if (newSize === 30) {
        //   this.showPageData = this.tableData.slice(0, 29);
        // }
      }
      this.queryInfo.page = 1;
      // this.getShoppingList();
      console.log(`每页 ${newSize} 条`);
    },
    _handleCurrentChange(data) {
      this.queryInfo.page = data;
      if (this.tableData.length !== 0) {
        if (this.tablePage.size === 10) {
          this.showPageData = this.tableData.slice(
            (data - 1) * 10,
            (data - 1) * 10 + 10
          );
        }
        if (this.tablePage.size === 20) {
          this.showPageData = this.tableData.slice(
            (data - 1) * 20,
            (data - 1) * 20 + 20
          );
        }
        if (this.tablePage.size === 30) {
          this.showPageData = this.tableData.slice(
            (data - 1) * 30,
            (data - 1) * 30 + 30
          );
        }
        if (this.tablePage.size === 40) {
          this.showPageData = this.tableData.slice(
            (data - 1) * 40,
            (data - 1) * 40 + 40
          );
        }
      }
    },
    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = val;
    },
    dialogHandleSelectionChange(val) {
      this.dialogMultipleSelection = val;
    },
    tableRowClassName({ row, rowIndex }) {
      console.log(rowIndex, "rowIndex");
      if (rowIndex === 0) {
        return "title-row";
      }
      return "";
    },
    async getShoppingList() {
      const { data: myLiveData } = await getMyLive(this.myLiveInfo);
      const { data: businessDatas } = await searchBusinessInfoByUserId({
        userId: this.myLiveInfo.user_id,
      });

      console.log(businessDatas, "businessDatas", myLiveData);
      this.businessId = businessDatas.body.id;
      this.roomId = myLiveData.id;
      this.queryInfo = {
        roomId: myLiveData.id,
        businessId: businessDatas.body.id,
        page: this.queryInfo.page,
      };
      const { data: res } = await asyncShoppingList(this.queryInfo);
      this.tableData = res.body.list;
      this.showPageData = res.body.list.slice(0, 10);
      this.shopTotal = res.body.total;
    },
    async pushToBabyPocket() {
      const dialogMultipleSelectionIds = [];
      this.dialogMultipleSelection.forEach((item, index) => {
        dialogMultipleSelectionIds.push(item.id);
      });
      if (dialogMultipleSelectionIds.length === 0) {
        Message.error("请选择要推送的宝贝");
        throw new Error("请选择要推送的宝贝");
      }
      this.babyDialogVisible = false;

      await insertLiveGoods({
        businessId: this.businessId,
        goodsIds: dialogMultipleSelectionIds,
        num: 0,
        // id: 0,
        roomId: this.roomId,
        status: 0,
        userId: this.myLiveInfo.user_id,
      });
      this.$router.push("/babyPocket");
    },
    handleClose() {
      this.babyDialogVisible = false;
    },
    async deleteBaby(subItem) {
      this.multipleSelection = this.multipleSelection.filter(
        (item) => item.id !== subItem.id
      );
      console.log(subItem, "subItem", this.multipleSelection);
      // this.multipleSelection = val;

      // await deleteBabyData();
      // this.getShoppingList();
    },
  },
  created() {
    this.getShoppingList();
  },
};
</script>

<style scoped lang="scss">
.content {
  background: #fff;
  padding: 20px;
}
.el-table .title-row {
  background: #f3f7fa;
}
.pagebottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .nextStep {
    margin-top: 15px;
  }
}
</style>
<style>
.babyDialog .el-dialog__footer {
  text-align: left !important;
}
</style>
