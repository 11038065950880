










































































































































































































































































































.content {
  background: #fff;
  padding: 20px;
}
.el-table .title-row {
  background: #f3f7fa;
}
.pagebottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .nextStep {
    margin-top: 15px;
  }
}
